body {
  background: #cccbcb; 
}
.App {
  text-align: center;
}
.container {
  background-color: #ffffff;
  margin: 5px;
  height: 90vh;
  width: 90vw;
}